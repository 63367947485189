import type { DefineImageStyleConfig } from '#rokka/types'
import type { Viewport } from '#rokka/generated-types'
import { rokkaConfig } from '~/nuxtConfig/rokka'

type DefineImageStyleConfigSizesSingle = {
  type: 'sizes-single'
  aspectRatio?: number
  width: number
}
type DefineImageStyleConfigScale = {
  type: 'scale'
  pictures: Partial<
    Record<
      Viewport,
      {
        widthScale: number
        aspectRatio?: number
      }
    >
  >
}

const rokkaViewportsArray = Object.entries(rokkaConfig.viewports).sort(
  ([, v1], [, v2]) => v1 - v2,
)
const lowestRokkaViewport = rokkaViewportsArray[0][0]
const createImageStyleConfigSizesSingle = (
  config: DefineImageStyleConfigSizesSingle,
) => {
  return defineImageStyle({
    type: 'sizes',
    aspectRatio: config.aspectRatio,
    sizes: {
      [lowestRokkaViewport]: config.width,
    },
  })
}

const nextScreenSize = (viewport: string): number => {
  const currentIndex = rokkaViewportsArray.findIndex(
    (element) => element[0] === viewport,
  )
  const nextIndex = currentIndex + 1
  if (nextIndex >= rokkaViewportsArray.length) {
    return rokkaViewportsArray[currentIndex][1] * 2
  }

  return rokkaViewportsArray[currentIndex + 1][1]
}

// {
//   type: 'scale',
//   pictures: {
//     lg: {
//       widthScale: 0.5,
//       aspectRatio: 2 / 1,
//     },
//   },
// }
//
// =>
//
// {
//   type: 'pictures',
//   pictures: {
//     lg: {
//       width: 0.5 * widthXl,
//       aspectRatio: 2 / 1,
//     },
//     xl: {
//       width: 0.5 * widthXxl,
//       aspectRatio: 2 / 1,
//     },
//     xxl: {
//       width: 0.5 * widthXxxl,
//       aspectRatio: 2 / 1,
//     },
//     xxxl: {
//       width: 0.5 * maxWidth,
//       aspectRatio: 2 / 1,
//     },
//   },
// }
const createImageStyleConfigScale = (config: DefineImageStyleConfigScale) => {
  return defineImageStyle({
    type: 'pictures',
    pictures: Object.fromEntries(
      rokkaViewportsArray
        .map(([viewport, _size], index) => {
          let value
          if (viewport in config.pictures) {
            value = config.pictures[viewport as Viewport]
          } else {
            // find next smaller viewport which is defined
            for (let i = index; i >= 0; i--) {
              const rokkaViewport = rokkaViewportsArray[i][0]
              if (rokkaViewport in config.pictures) {
                value = config.pictures[rokkaViewport as Viewport]
                break
              }
            }
          }

          if (value) {
            return [
              viewport,
              {
                width: Math.ceil(value.widthScale * nextScreenSize(viewport)),
                aspectRatio: value.aspectRatio,
              },
            ]
          } else {
            return []
          }
        })
        .filter((e) => e.length > 0),
    ),
  })
}

export default function (
  config:
    | DefineImageStyleConfig
    | DefineImageStyleConfigSizesSingle
    | DefineImageStyleConfigScale,
) {
  let finalConfig: DefineImageStyleConfig

  if (config.type === 'sizes-single') {
    finalConfig = createImageStyleConfigSizesSingle(config)
  } else if (config.type === 'scale') {
    finalConfig = createImageStyleConfigScale(config)
  } else {
    finalConfig = config
  }

  return defineImageStyle(finalConfig)
}
